<template>
    <div class="w-full">
        <validation-provider
            v-slot="{ errors }"
            :name="name"
            :rules="rules"
        >
            <b-field
                :label="label"
                :type="{
                    [InputContext.DEFAULT]: false,
                    [InputContext.NEGATIVE]: errors[0],
                    [InputContext.POSITIVE]: false,
                    [InputContext.INFO]: false,
                    [InputContext.WARN]: false,
                }"
                :message="errors"
            >
                <b-input
                    v-model="innerValue"
                    ref="textInput"
                    :name="name"
                    :type="type"
                    :size="size"
                    :expanded="expanded"
                    :required="required"
                    :disabled="disabled"
                    :password-reveal="passwordReveal"
                    :loading="loading"
                    :icon-pack="iconPack"
                    :icon="icon"
                    :placeholder="placeholder"
                    :maxlength="maxlength"
                    :has-counter="hasCounter"
                    :custom-class="customClass"
                    :rounded="rounded"
                ></b-input>

            </b-field>
        </validation-provider>
    </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

// theme context
const InputContext = {
    DEFAULT: 'is-default',
    WARN: 'is-warning',
    NEGATIVE: 'is-danger',
    POSITIVE: 'is-success',
    INFO: 'is-info',
    DISABLED: 'disabled',
};

const InputSize = {
    SMALL: 'is-small',
    DEFAULT: 'is-default',
    MEDIUM: 'is-medium',
    LARGE: 'is-large',
};

const SUPPORTED_INPUT_SIZES = Object.values(InputSize);

export default {
    name: 'TextInput',

    components: {
        ValidationProvider,
    },

    props: {
        // must be included in props
        value: {
            type: [String, Number],
            required: false,
            default: () => '',
        },
        vid: {
            type: String,
            required: false,
            default: () => undefined,
        },
        rules: {
            type: [Object, String, Date],
            default: () => '',
        },
        name: {
            type: String,
            required: false,
            default: () => '',
        },
        label: {
            type: String,
            required: false,
            default: () => '',
        },
        type: {
            type: String,
            required: false,
            default: () => 'text',
        },
        size: {
            type: String,
            required: false,
            default: () => InputSize.DEFAULT,
        },
        expanded: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        required: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        passwordReveal: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        loading: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        rounded: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        iconPack: {
            type: String,
            required: false,
            default: () => 'fas',
        },
        icon: {
            type: String,
            required: false,
            default: () => undefined,
        },
        placeholder: {
            type: String,
            required: false,
            default: () => '',
        },
        maxlength: {
            type: [Number, String],
            required: false,
            default: () => undefined,
        },
        hasCounter: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        customClass: {
            type: String,
            required: false,
            default: () => 'underline-input',
        },
    },

    data() {
        return {
            InputContext,
            innerValue: '',
        };
    },

    computed: {},

    watch: {
        // handles internal model changes
        innerValue(newVal) {
            return this.$emit('input', newVal);
        },
        // handles external model changes
        value(newVal) {
            this.innerValue = newVal;
        },
        size() {
            if (!SUPPORTED_INPUT_SIZES.includes(this.size)) {
                throw new Error(`TextInput# Illegal size '${this.size}', expected one of [${SUPPORTED_INPUT_SIZES}].`);
            }
        },
    },

    created() {
        if (this.value) {
            this.innerValue = this.value;
        }

        if (!SUPPORTED_INPUT_SIZES.includes(this.size)) {
            throw new Error(`TextInput# Illegal size '${this.size}', expected one of [${SUPPORTED_INPUT_SIZES}].`);
        }
    },

    methods: {},
};
</script>

<style>
.underline-input {
    border-left: 1px solid transparent !important;
    border-top: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
    border-bottom: 1px solid black !important;
}
</style>
